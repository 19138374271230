<template>
    <div>
        <router-link
            v-if="prevRoute"
            :to="{
                name: prevRoute.name,
                params: { selectParams: selectParams }
            }"
            class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3 mb-4"
        >
            <i class="fas fa-arrow-left mr-2"></i>
            <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
        </router-link>
        <div class="min-h-100 d-flex flex-column h-100">
            <div class="row">
                <div class="col-xxl-12">
                    <div class="card card-custom gutter-b">
                        <div class="card-header border-0 pt-2 pb-0">
                            <h3 class="card-title align-items-start justify-content-center flex-column">
                                <span class="card-label font-weight-bolder text-dark">{{ $t('GENERAL.FILTER') }}</span>
                            </h3>
                        </div>
                        <div class="card-body py-0">
                            <div :class="isMobile() ? 'flex-column' : ''" class="d-flex justify-content-between">
                                <div :class="isMobile() ? 'w-100' : ''" class="form-group w-22">
                                    <label>Nom</label>
                                    <div class="d-flex justify-center align-items-center">
                                        <b-form-input v-model="filterLastname" class="form-control form-control-solid" placeholder="Nom" />
                                        <i class="fas fa-times-circle ml-2" @click="filterLastname = ''"></i>
                                    </div>
                                </div>
                                <div :class="isMobile() ? 'w-100' : ''" class="form-group w-22">
                                    <label>Prénom</label>
                                    <div class="d-flex justify-center align-items-center">
                                        <b-form-input
                                            v-model="filterFirstname"
                                            class="form-control form-control-solid"
                                            placeholder="Prénom"
                                        />
                                        <i class="fas fa-times-circle ml-2" @click="filterFirstname = ''"></i>
                                    </div>
                                </div>
                                <div :class="isMobile() ? 'w-100' : ''" class="form-group w-22">
                                    <label>Date d'obtention</label>
                                    <div class="d-flex justify-center align-items-center">
                                        <VueDatePicker
                                            v-model="filterDate"
                                            :visible-years-number="10"
                                            format="DD/MM/YYYY"
                                            fullscreen-mobile
                                            placeholder="Début - Fin"
                                            range
                                            range-header-text="Du %d au %d"
                                            range-input-text="Du %d au %d"
                                        />
                                        <i class="fas fa-times-circle ml-2" @click="filterDate = new Date()"></i>
                                    </div>
                                </div>
                                <div :class="isMobile() ? 'w-100' : ''" class="form-group w-22">
                                    <label>Date d'expiration</label>
                                    <div class="d-flex justify-center align-items-center">
                                        <VueDatePicker
                                            v-model="filterExpiry"
                                            :visible-years-number="10"
                                            format="DD/MM/YYYY"
                                            fullscreen-mobile
                                            placeholder="Début - Fin"
                                            range
                                            range-header-text="Du %d au %d"
                                            range-input-text="Du %d au %d"
                                        />
                                        <i class="fas fa-times-circle ml-2" @click="filterExpiry = new Date()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="min-h-100 d-flex flex-column h-100">
            <div class="row">
                <div class="col-xxl-12">
                    <div class="card card-custom gutter-b">
                        <div class="card-body p-0">
                            <div class="d-flex justify-content-between align-items-center">
                                <vue-excel-xlsx
                                    :columns="headersExcel"
                                    :data="excelData()"
                                    :file-name="excelName('file')"
                                    :file-type="'xlsx'"
                                    :sheet-name="excelName('sheet')"
                                    class="mt-4 ml-4"
                                >
                                    <button class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3 mb-4">
                                        <i class="fas fa-file-excel mr-2"></i>
                                        <span class="d-none d-sm-inline">{{ $t('GENERAL.EXPORT') }}</span>
                                    </button>
                                </vue-excel-xlsx>
                                <div class="mt-4 mr-4 d-flex justify-content-center align-items-center">
                                    <b-form-checkbox
                                        class="mb-1"
                                        v-model="displayAll"
                                        id="displayAll"
                                        switch
                                        size="lg"
                                        @change="getData()"
                                        :disabled="loading"
                                    ></b-form-checkbox>
                                    <label for="displayAll" class="ml-1 mb-0">Afficher tous les utilisateurs</label>
                                </div>
                            </div>
                            <v-data-table
                                :headers="headers"
                                :items="trainedUsers"
                                :items-per-page="itemsPerPage"
                                :loading="loading"
                                :page.sync="currentPage"
                                :sort-desc="false"
                                hide-default-footer
                                loading-text="Chargement en cours ..."
                                locale="fr-FR"
                                no-data-text="Aucun résultat"
                                no-results-text="Aucun résultat correspondant à votre recherche"
                                responsive
                                sort-by="expiry"
                                @pagination="paginationMethod"
                            >
                                <template v-slot:item.date="{ item }">
                                    {{ item.date | moment('Do MMMM YYYY') }}
                                </template>
                                <template v-slot:item.expiry="{ item }">
                                    {{ item.expiry | moment('Do MMMM YYYY') }}
                                </template>
                                <template v-slot:item.source="{ item }">
                                    <p v-if="item.source === 1" class="m-0">Distanciel</p>
                                    <p v-else class="m-0">Présentiel</p>
                                </template>
                                <template v-slot:item.statut="{ item }">
                                    <v-chip :style="'color: white; background-color:' + getStatut(item.expiry).color">
                                        {{ getStatut(item.expiry).text }}
                                    </v-chip>
                                </template>
                            </v-data-table>
                            <div class="row my-5">
                                <div class="col-xxl-12">
                                    <div>
                                        <b-pagination
                                            v-model="currentPage"
                                            :per-page="itemsPerPage"
                                            :total-rows="totalRows"
                                            align="center"
                                            class="mb-0"
                                            size="lg"
                                        >
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module'
import { GET_USERS_THEMES } from '@/core/services/store/api/statistique'
import { GET_THEME } from '@/core/services/store/api/theme'
import { GET_CUSTOMER } from '@/core/services/store/api/customer'
import moment from 'moment/moment'

export default {
    props: {
        customer_id: {
            type: Number,
            required: true
        },
        theme_id: {
            type: Number,
            required: true
        },
        selectParams: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            prevRoute: null,
            loading: true,
            trainedUsers: [],
            filterLastname: '',
            filterFirstname: '',
            filterDate: new Date(),
            filterExpiry: new Date(),
            search: '',
            currentPage: 1,
            itemsPerPage: 10,
            themeName: '',
            customerName: '',
            totalRows: 0,
            displayAll: false,
            headersExcel: [
                { label: this.$t('STATISTIQUES.DETAILS.LASTNAME'), field: 'lastname' },
                { label: this.$t('STATISTIQUES.DETAILS.FIRSTNAME'), field: 'firstname' },
                { label: this.$t('STATISTIQUES.DETAILS.SOURCE'), field: 'source', dataFormat: this.sourceFormat },
                { label: this.$t('STATISTIQUES.DETAILS.DATE_OBTAINED'), field: 'date', dataFormat: this.dateFormat },
                { label: this.$t('STATISTIQUES.DETAILS.DATE_EXPIRY'), field: 'expiry', dataFormat: this.dateFormat },
                { label: this.$t('STATISTIQUES.GENERAL.STATUT'), field: 'statut' }
            ]
        }
    },
    computed: {
        headers() {
            return [
                { text: this.$t('STATISTIQUES.DETAILS.LASTNAME'), value: 'lastname', filter: this.lastnameFilter },
                { text: this.$t('STATISTIQUES.DETAILS.FIRSTNAME'), value: 'firstname', filter: this.firstnameFilter },
                { text: this.$t('STATISTIQUES.DETAILS.SOURCE'), value: 'source' },
                { text: this.$t('STATISTIQUES.DETAILS.DATE_OBTAINED'), value: 'date', filter: this.dateFilter },
                { text: this.$t('STATISTIQUES.DETAILS.DATE_EXPIRY'), value: 'expiry', filter: this.expiryFilter },
                { text: this.$t('STATISTIQUES.GENERAL.STATUT'), value: 'statut' }
            ]
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    created() {
        //Si les props ne sont pas définies, on redirige vers la page de statistiques
        if (!this.customer_id || !this.theme_id) {
            this.$router.push({ name: 'statistiques' })
        }
    },
    async mounted() {
        this.$store.dispatch(GET_THEME, this.theme_id).then(response => {
            this.themeName = response.name
        })
        this.$store.dispatch(GET_CUSTOMER, this.customer_id).then(response => {
            this.customerName = response.data.name
        })
        await this.getData()
    },
    methods: {
        async getData() {
            this.loading = true
            this.trainedUsers = []
            this.$store
                .dispatch(GET_USERS_THEMES, {
                    customerId: this.customer_id,
                    themeId: this.theme_id,
                    expiry: this.displayAll ? 9999 : 3
                })
                .then(response => {
                    Object.keys(response).forEach(key => {
                        this.trainedUsers.push({
                            lastname: response[key].lastname,
                            firstname: response[key].firstname,
                            date: response[key].date,
                            expiry: response[key].expiry,
                            source: response[key].source,
                            statut: this.getStatut(response[key].expiry).text
                        })
                    })
                    this.totalRows = this.trainedUsers.length
                    this.loading = false
                })
        },
        excelName(type) {
            switch (type) {
                case 'file':
                    if (this.customerName.length + this.themeName.length < 28) {
                        return this.customerName + ' - ' + this.themeName
                    } else {
                        return this.customerName.substring(0, 31)
                    }
                case 'sheet':
                    return this.themeName.substring(0, 31)
            }
        },
        getStatut(date) {
            //Si la date d'expiration est dépassée, on retourne un statut rouge
            //Si la date d'exipration est dans moins de 3 mois, on retourne un statut orange
            //Sinon, on retourne un statut vert
            if (moment(date).isBefore(moment())) {
                return { text: 'Expiré (' + Math.abs(moment(date).diff(moment(), 'days')) + ' jours)', color: '#e53935' }
            } else if (moment(date).isBefore(moment().add(3, 'months'))) {
                return { text: 'Bientôt expiré', color: '#ffa000' }
            } else {
                return { text: 'Valide', color: '#4CAF50' }
            }
        },
        sourceFormat(value) {
            //si value = 1, alors distanciel, sinon présentiel
            return value === 1 ? 'Distanciel' : 'Présentiel'
        },
        dateFormat(value) {
            return moment(value).format('DD/MM/YYYY')
        },
        paginationMethod(pagination) {
            this.totalRows = pagination.itemsLength
        },
        excelData() {
            if (this.filterLastname || this.filterFirstname || this.filterDate.start || this.filterExpiry.start) {
                let users = this.trainedUsers.filter(user => {
                    if (this.filterLastname) {
                        if (user.lastname.toLowerCase().includes(this.filterLastname.toLowerCase())) {
                            return user
                        }
                    } else if (this.filterFirstname) {
                        if (user.firstname.toLowerCase().includes(this.filterFirstname.toLowerCase())) {
                            return user
                        }
                    } else if (this.filterDate.start) {
                        if (user.date >= this.filterDate.start && user.date <= this.filterDate.end) {
                            return user
                        }
                    } else if (this.filterExpiry.start) {
                        if (user.expiry >= this.filterExpiry.start && user.expiry <= this.filterExpiry.end) {
                            return user
                        }
                    }
                })
                return users
            } else {
                return this.trainedUsers
            }
        },
        lastnameFilter(value) {
            if (!value) {
                return true
            }
            if (!this.filterLastname) {
                return true
            }
            return value.toLowerCase().includes(this.filterLastname.toLowerCase())
        },
        firstnameFilter(value) {
            if (!value) {
                return true
            }
            if (!this.filterFirstname) {
                return true
            }
            return value.toLowerCase().includes(this.filterFirstname.toLowerCase())
        },
        dateFilter(value) {
            if (!value) {
                return true
            }
            if (!this.filterDate.start) {
                return true
            }
            value = new Date(value)
            let start = new Date(this.filterDate.start)
            let end = new Date(this.filterDate.end)
            return value >= start && value <= end
        },
        expiryFilter(value) {
            if (!value) {
                return true
            }
            if (!this.filterExpiry.start) {
                return true
            }
            value = new Date(value)
            let start = new Date(this.filterExpiry.start)
            let end = new Date(this.filterExpiry.end)
            return value >= start && value <= end
        }
    },
    watch: {
        customerName: function() {
            if (this.customerName && this.themeName) {
                this.$store.dispatch(
                    SET_PAGETITLE,
                    "Détails de l'effectif " + this.customerName + ' formé pour le thème ' + this.themeName.toLocaleLowerCase()
                )
            }
        },
        themeName: function() {
            if (this.customerName && this.themeName) {
                this.$store.dispatch(
                    SET_PAGETITLE,
                    "Détails de l'effectif " + this.customerName + ' formé pour le thème ' + this.themeName.toLocaleLowerCase()
                )
            }
        }
    }
}
</script>
<style>
.w-22 {
    width: 22%;
}
</style>
